<template>
  <v-container class="py-7">
    <div v-if="!order" class="col text-center">Загрузка...</div>

    <div v-else class="order-status-container">
      <div class="order">
        <div class="heading">Заказ №{{ orderID }}</div>
        <span
          class="order-status"
          :class="[order.status === 'Оплачен' && 'payed']"
          >{{ order.status }}</span
        >
      </div>

      <div v-if="order.status === 'Ожидает оплаты'">
        <template v-if="isSbp">
          <h2>Выберите банк для оплаты</h2>
          <p>
            После выбора банка, откроется банковское приложение, где вы сможете
            совершить оплату.
          </p>

          <button
            class="sbp-default-button v-btn v-btn--has-bg theme--light v-size--x-large primary"
            type="button"
          >
            <a
              class="default-link external-link v-btn__content"
              :href="this.$route.query.redirect"
              target="_blank"
              >Открыть приложение по умолчанию</a
            >
          </button>

          <h3 class="banks-title">Другие банки</h3>
          <div class="search-wrapper mb-4">
            <input
              v-model="searchInput"
              class="search-input"
              type="text"
              placeholder="Найти банк"
            />
            <img
              class="search-icon"
              src="@/assets/images/search.svg"
              alt="search"
            />
          </div>

          <div class="sbp-links">
            <a
              v-for="(bank, i) in filteredBanks"
              :key="i"
              :href="redirectURLmobile(bank)"
              class="sbp-button external-link"
            >
              <div class="bank-image-wrapper">
                <img class="bank-image" :src="bank.logoURL" alt="bank-logo" />
                {{ bank.bankName }}
              </div>
              <img src="@/assets/images/arrow.svg" alt="arrow" />
            </a>
          </div>
        </template>

        <template v-else>
          <div class="pay-link-wrapper">
            <a :href="paymentURL" class="external-link pay-link">
              Оплатить заказ
            </a>
          </div>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import banksData from '@/data/banksData.json'

export default {
  data: () => ({
    order: null,
    searchInput: '',
    banks: banksData,
  }),

  computed: {
    orderID() {
      return this.$route.query.id
    },

    filteredBanks() {
      return this.banks.filter((bank) => {
        if (this.searchInput) {
          return bank.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
        } else {
          return bank
        }
      })
    },

    isSbp() {
      return (
        this.order.payment_type === 'sbp_sberbank' ||
        this.order.payment_type === 'sbp_tinkoff'
      )
    },

    paymentPending() {
      return Boolean(
        this.order?.status?.toLowerCase() === 'ожидает оплаты' &&
          this.paymentURL
      )
    },

    paymentURL() {
      return this.$route.query.redirect
    },
  },

  async mounted() {
    try {
      await this.getData()
      setInterval(() => {
        this.getData()
      }, 10000)
    } catch {
      /*  */
    }
  },

  methods: {
    async getData() {
      const baseURL = 'https://vsem-edu-oblako.ru/singlemerchant/'
      const params = { id: this.orderID }
      const res = await this.$api.get(baseURL + '/rest/order/get', {
        params,
      })
      this.order = res.details
    },

    redirectURLmobile(bank) {
      const paymentUrl = new URL(this.paymentURL)
      if (bank.webClientUrl) {
        return bank.webClientUrl + paymentUrl.pathname + paymentUrl.search
      } else {
        paymentUrl.href = paymentUrl.href.replace('https', bank.schema)
        return paymentUrl
      }
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.order {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 25px;
}

.order-status {
  padding: 5px 10px;
  border-radius: 15px;
  color: white;
  background: #d53f8c;
}

.payed {
  background: #83cd26;
}

.sbp-description {
  list-style-type: none;
  li {
    text-indent: -19px;
  }
  li:before {
    content: '-';
    margin-right: 10px;
  }
}

.sbp-default-button {
  width: 100%;
}

.default-link {
  width: 100%;
  color: white;
}

.banks-title {
  margin: 16px 0px;
}

.search-wrapper {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #a5b0b8;
  &:focus {
    outline: 1px solid black;
  }
  .theme--dark & {
    border: none;
    background: #3e4146;
    color: white;
    &:focus {
      outline: 1px solid #a5b0b8;
    }
    &::placeholder {
      color: #a5b0b8;
    }
  }
}

.search-icon {
  position: absolute;
  top: 13px;
  right: 15px;
  width: 18px;
  height: 18px;
}

.sbp-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
}

.sbp-button {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 0 10px 6px rgba(34, 60, 80, 0.09);
  color: black;
  .theme--dark & {
    color: white;
    background: #5e6267;
  }
}

.bank-image-wrapper {
  display: flex;
  gap: 8px;
}

.bank-image {
  height: 24px;
  border-radius: 5px;
}

.pay-link-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pay-link {
  background: var(--v-primary-base);
  color: white;
  padding: 10px 14px;
  border-radius: 16px;
}
</style>
